import React, { useEffect, useState } from "react";

import { Spinner } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Title, Text, Button } from "../components/Core";
import styled from "styled-components";

import flopPmLogoLightPng from "../assets/image/flop/flop-pm-logo-light.png";
import { Link } from "gatsby";

const RootDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #1a2025;
  z-index: 9999999999;
`;

const ContainerDiv = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  a {
    color: #ffffff !important;
    text-decoration: underline;
  }
`;

const Img = styled.img`
  width: 310px;
`;

const ConfirmationState = {
  WAITING: "waiting",
  CONFIRMED: "confirmed",
  FAILED: "failed",
  RETRY_AFTER_FAIL: "retry_after_fail",
};

const EmailConfirmation = ({ status, stateHandler }) => {
  const retryVerification = () => {
    let params = new URLSearchParams(document.location.search.substring(1));
    let id = params.get("id");
    (async function retryEmailConfirmationWorkflow() {
      const { ok } = await fetch(
        `${process.env.GATSBY_PROSPECT_API_URL}/prospects/${id}/reminder`,
        {
          method: "POST",
          mode: "cors",
        }
      );
      // Call parent component update state method
      stateHandler(
        ok ? ConfirmationState.RETRY_AFTER_FAIL : ConfirmationState.FAILED
      );
    })();
  };

  if (status === ConfirmationState.WAITING) {
    return (
      <div>
        <Spinner animation="border" variant="light" />
      </div>
    );
  } else if (status === ConfirmationState.CONFIRMED) {
    return (
      <>
        <Text color="light">Thanks for validating your email!</Text>
        <Text variant="small" color="light" className="py-4">
          Your email address has been validated. Our compliance team will now
          analyse your free trial request and comes back to you within two
          working days.
        </Text>
        <Link to="/flop-pm" style={{ textDecoration: "none" }}>
          <Button py={1} variant="outline" color="#4D8DCC">
            Ok, got it
          </Button>
        </Link>
      </>
    );
  } else if (status === ConfirmationState.RETRY_AFTER_FAIL) {
    return (
      <>
        <Text color="light">New email sent</Text>
        <Text variant="small" color="light" className="py-4">
          A new confirmation email has been sent. Please, check your mailbox and
          use the provided link to confirm your email address.
        </Text>
        <Link to="/flop-pm" style={{ textDecoration: "none" }}>
          <Button py={1} variant="outline" color="#4D8DCC">
            Ok, got it
          </Button>
        </Link>
      </>
    );
  }

  return (
    <>
      <Text color="light">Oops, something went wrong...</Text>
      <Text variant="small" color="light" className="py-4">
        We were not able to verify your email address, please hit the retry
        button. If the problem persists, please contact our support team{" "}
        <a href="mailto:support@firstlandofpoker.com">here</a>.
      </Text>
      <Button
        onClick={retryVerification}
        py={1}
        variant="outline"
        color="#4D8DCC"
      >
        Retry
      </Button>
    </>
  );
};

const ProspectConfirmationPage = () => {
  const [isConfirmed, setIsConfirmed] = useState(ConfirmationState.WAITING);

  useEffect(() => {
    let params = new URLSearchParams(document.location.search.substring(1));
    let id = params.get("id");
    let activationToken = params.get("activationToken");

    // Use IIFE
    (async function confirmEmail() {
      const { ok } = await fetch(
        `${process.env.GATSBY_PROSPECT_API_URL}/prospects/${id}/activate?activationToken=${activationToken}`,
        {
          mode: "cors",
        }
      );
      setIsConfirmed(
        ok ? ConfirmationState.CONFIRMED : ConfirmationState.FAILED
      );
    })();
    // Empty dependency array act as componentDidMount
  }, []);

  return (
    <>
      <RootDiv>
        <ContainerDiv>
          <Col>
            <Title color="light" variant="small-section">
              Welcome To
            </Title>
            <Img
              src={flopPmLogoLightPng}
              alt="First land of poker prospect informations"
              className="pb-5"
            ></Img>
            <EmailConfirmation
              status={isConfirmed}
              stateHandler={(newConfirmationState) => {
                setIsConfirmed(newConfirmationState);
              }}
            />
          </Col>
        </ContainerDiv>
      </RootDiv>
    </>
  );
};
export default ProspectConfirmationPage;
